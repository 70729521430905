.aboutDHC {
  height: auto;
  margin: 10px 0 40px;
  padding: 50px 2vw;
}

@media screen and (min-width: 640px) {
  .aboutDHC {
    height: 300px;
    margin: 100px 0;
    padding: 0 2vw;
  }
}

@media screen and (min-width: 768px) {
  .aboutDHC {
    height: 300px;
    margin: 100px 0;
  }
}

@media screen and (min-width: 1024px) {
  .aboutDHC {
    height: 46vh;
    margin: 22vh 0 15vh;
    padding: 0 2vw;
  }
}
