.linkStore {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.linkCard {
  backdrop-filter: blur(5px) saturate(180%);
  -webkit-backdrop-filter: blur(5px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 9999px;
  border: 2px solid #1e293b;
}

.linkCard:hover {
  background-color: rgba(255, 255, 255, 0.55);
}
