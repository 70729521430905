.subscribeModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  background: rgba($color: #000000, $alpha: 0.3);
  overflow: hidden !important;
  justify-content: center;
  align-items: center;

  &-card {
    box-shadow: 14px 18px 19px rgba(0, 0, 0, 0.25);
  }
}
