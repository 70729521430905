@media screen and (max-width: 1023px) {
    .testimonial {
      &__list {
        border-radius: 0;
  
        // Scroll Snap
        overflow-x: scroll;
        scrollbar-width: none;
        scroll-snap-type: x mandatory;
  
        &::-webkit-scrollbar {
          display: none;
        }
  
        &-item {
          scroll-snap-stop: always;
          scroll-snap-align: start;
        }
      }
    }
  }

  @media screen and (max-width: 340px) {
    .testimonial {
      &-box {
        height: 240px;
        font-size: 13px;
      }
    }
  }