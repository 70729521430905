.activity {
  margin-bottom: 12vh;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

@media screen and (max-width: 640px) {
  .activity {
    margin-bottom: 6vh;
  }
}

@media screen and (max-width: 380px) {
  .activity {
    margin-bottom: 6vh;
  }
}

@media screen and (max-width: 350px) {
  .activity {
    margin-bottom: 6vh;
  }
}
