.reviewMarketplace {
  &__list {
    border-radius: 0;
    // Scroll Snap
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
  
    &::-webkit-scrollbar {
      display: none;
    }
  
    &-item {
      scroll-snap-stop: always;
      scroll-snap-align: start;
        }
    }
}

