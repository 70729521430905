.productDetails {
  &__main {
    &-productTitle {
      background: var(--color-gradient-primary);

      background-size: 100%;
      background-repeat: repeat;

      /* This will show the gradient as a text color rather than element bg. */
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      -ms-background-clip: text;
      -ms-text-fill-color: transparent;
      -o-background-clip: text;
      -o-text-fill-color: transparent;
    }
  }
  ul {
    padding: 0 16px;
    li {
      list-style-position: outside;
      list-style-type: disc;
    }
  }

  .badgeHover {
    width: auto;
    height: calc(350px / 4);
    bottom: -105px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    display: flex;
    justify-content: center;
    overflow: visible;

    p {
      margin-top: 5px;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 50;
      color: #ffffff;
    }
  }

  &-share {
    padding: 8px;
    background: var(--color-gradient-primary);
    border-radius: 200px;
    border: 1px solid rgba(209, 213, 219, 0.4);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 1000;
  }
  &-fyi {
    z-index: 1000;
    box-shadow: rgba(100, 100, 111, 0.25) 2px 2px 12px;
  }
}
