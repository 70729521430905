.contactUsPage {
  &__main {
    &-form {
      background-color: #ffffff;
      border-radius: 10px;
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }
    &-form:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }

    input,
    textarea {
      // background-color: #f8f8f8;
      border-bottom: 1px solid var(--color-graylight);
      padding: 0.5rem;
      background-color: #ffffff;
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    input:focus,
    textarea:focus {
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 4px;
    }

    &-info {
      background-color: var(--color-primary);
      border-radius: 10px;
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }
    &-info:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
  }
}

@media screen and (max-width: 640px) {
  .contactUsPage {
    &__main {
      &-form {
        background-color: #ffffff;
        border-radius: 0;
        box-shadow: none;
      }
      &-form:hover {
        box-shadow: none;
      }

      input,
      textarea {
        // background-color: #f8f8f8;
        border-bottom: 1px solid var(--color-graylight);
        padding: 0.5rem;
        background-color: #ffffff;
        transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      input:focus,
      textarea:focus {
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 4px;
      }

      &-info {
        background-color: var(--color-primary);
        border-radius: 0;
        box-shadow: none;
      }
      &-info:hover {
        box-shadow: none;
      }
    }
  }
}
