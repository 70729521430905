.activityDetails {
  margin-bottom: 12vh;

  .article__content {
    a {
      text-decoration: underline;
    }

    h2 {
      font-size: 18px;
      font-weight: 700;
    }

    strong {
      font-weight: 500;
    }

    ul {
      padding: 8px 0 0 30px;
      list-style: disc outside;

      li {
        padding-bottom: 8px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .activityDetails {
    margin-bottom: 6vh;
  }
}

@media screen and (max-width: 380px) {
  .activityDetails {
    margin-bottom: 6vh;
  }
}

@media screen and (max-width: 350px) {
  .activityDetails {
    margin-bottom: 6vh;
  }
}
