.productSliderMobile {
  &__slider {
    scrollbar-width: none;
    overflow-x: scroll;

    // Scroll Snap
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }

    &-image {
      scroll-snap-stop: always;
      scroll-snap-align: center;
      object-fit: cover;
    }
  }
}
