.navbar {
  position: fixed;
  width: 100%;
  height: 12vh;
  padding: 0 4vw;
  z-index: 100;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  color: #ffffff;

  &__brand {
    object-fit: contain;
  }

  .navbar-underline {
    position: absolute;
    bottom: -3px;
    left: 20%;
    height: 1px;
    width: 60%;
    // background: var(--color-secondary); // Default
    // background: #995aa8; // Valentine Theme
    background: var(--color-yellow); // Independence Day
  }

  &__mobile {
    // padding-bottom: 16px;
    overflow: hidden;
  }
}

.navbar-primary {
  background: var(--color-primary);
}

.navbar-ramadhan {
  background: url("../../public/images/universal/navbar-ramadhan-sm.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-independenceDay {
  background: url("../../public/images/universal/navbar-independence-day-sm-v2.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-halloween {
  background: url("../../public/images/universal/navbar-halloween.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-christmas {
  background: url("../../public/images/universal/navbar-christmas-sm.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-chinese-ny {
  background: url("../../public/images/universal/navbar-chinese-ny-sm.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-chinese-ny-v2 {
  background: url("../../public/images/universal/navbar-chinese-ny-v2-sm.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-valentine {
  background: url("../../public/images/universal/navbar-valentine-sm.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-transparent {
  background: rgba(0, 57, 148, 0.85);
}

@media screen and (min-width: 300px) {
  .navbar {
    height: 8vh;
    padding: 0 4vw;
  }
}

@media screen and (min-width: 640px) {
  .navbar-christmas {
    background: url("../../public/images/universal/navbar-christmas.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (min-width: 1024px) {
  .navbar {
    height: 12vh;
    padding: 0 3.5vw;
  }

  .navbar-ramadhan {
    background: url("../../public/images/universal/navbar-ramadhan.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .navbar-independenceDay {
    background: url("../../public/images/universal/navbar-independence-day-v2.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .navbar-halloween {
    background: url("../../public/images/universal/navbar-halloween.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .navbar-christmas {
    background: url("../../public/images/universal/navbar-christmas.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .navbar-chinese-ny {
    background: url("../../public/images/universal/navbar-chinese-ny.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .navbar-chinese-ny-v2 {
    background: url("../../public/images/universal/navbar-chinese-ny-v2.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .navbar-valentine {
    background: url("../../public/images/universal/navbar-valentine.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
