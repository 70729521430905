.productAdvantages {
  &__box {
    height: 87%;
    background: var(--color-gradient-primary);
    border-radius: 50px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    -moz-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    -ms-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    -o-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
}

@media screen and (max-width: 640px) {
  .productAdvantages {
    &__box {
      height: auto;
      background: var(--color-gradient-primary);
      border-radius: 10px;
    }
  }
}
