.ourProduct {
  &__list {
    margin: 3vw;
    border-radius: 18px;
  }
}

@media screen and (max-width: 640px) {
  .ourProduct {
    &__list {
      margin: 0;
      border-radius: 0;

      // Scroll Snap
      overflow-x: scroll;
      scrollbar-width: none;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        display: none;
      }

      &-item {
        scroll-snap-stop: always;
        scroll-snap-align: start;
      }
    }
  }
}
