@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&family=Playfair+Display:wght@400;500;600;700;800;900&family=Ibarra+Real+Nova:wght@400;500;600;700&family=Ceviche+One&family=Lora:wght@400;500;600;700&display=swap");
// fonts:
//  sans-serif
// - nunito-sans
// - plus-jakarta-sans
// - roboto
//  serif
// - playfair display
// - ibarra real nova

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #003994;
  --color-dark-primary: #002d75;
  --color-light-primary: #1f5bbb;
  --color-surface-primary: #6aa3e7;
  --color-secondary: #f79e0e;

  --color-yellow: #fec24d;
  --color-green: #50a831;
  --color-orange: #faa74a;
  --color-orange-dark: #f68a50;
  --color-blue: #00a3df;
  --color-cyan: #00b7ba;
  --color-red: #f16047;
  --color-violet: #f0649c;

  --color-dark: #262626;
  --color-graylight: #717171;

  --color-gradient-primary: linear-gradient(
    90deg,
    #285daa 0%,
    #2759a3 24%,
    #244e91 58%,
    #203c74 97%,
    #203c73 99%
  );
}

// Layout Component
.layout {
  background-color: #f8f8f8;
}

.layout-gap {
  padding-top: 12vh;
}

.hover-gradient-primary:hover {
  background: var(--color-gradient-primary);
  color: #ffffff;
  border-color: rgba($color: #003994, $alpha: 0.1);
}

input {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  outline: none;
  resize: none;
}

.circle-dots {
  transform: scale(1.05);
}

.scrollbar-custom {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: rgb(240, 240, 240);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
    border-radius: 10px;
  }
}

.hide-scroll {
  overflow: hidden;
}

.absolute-x-center {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.absolute-y-center {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.shadow-card {
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.shadow-card-hover:hover {
  box-shadow: rgb(0 0 0 / 26%) 0px 5px 12px;
}

@media screen and (max-width: 1024px) {
  .layout-gap {
    padding-top: 8vh;
  }

  .breadcrumb::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .layout-gap {
    padding-top: 8vh;
  }

  .breadcrumb::-webkit-scrollbar {
    display: none;
  }
}
