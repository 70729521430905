.productList {
  min-height: 400px;
  margin: 12vh 0;

  .productList__main {
    width: 100%;
    height: 400px;
    background: url('../../public/images/universal/bg-product-list-sm.png');
    background-repeat: no-repeat;
    background-position: top;
    object-position: top;
    background-size: 100% auto;
    padding: 12px 4px;

    .product-slider {
      width: auto;
      cursor: grab;
      height: auto;

      .product-item {
        width: 44vw;
        flex-shrink: 0;
        border-radius: 16px;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
          rgba(0, 0, 0, 0.22) 0px 10px 10px;

        &-card {
          /* Glassmorphism card effect */
          backdrop-filter: blur(4px) saturate(120%);
          -webkit-backdrop-filter: blur(4px) saturate(120%);
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: 12px;
          border: 1px solid rgba(239, 244, 249, 0.6);
          overflow: hidden;
        }

        img {
          width: 44vw;
          height: auto;
          border-radius: 16px;
        }

        .product-item-btn {
          border-radius: 10px;
        }
      }
    }
    .product-slider:active {
      cursor: grabbing;
    }

    .product-slider-mobile-list {
      scrollbar-width: none;
      overflow-x: scroll;

      // Scroll Snap
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        display: none;
      }

      .product-item {
        width: 48.5vw;
        flex-shrink: 0;
        border-radius: 10px;
        overflow: hidden;

        scroll-snap-stop: always;
        scroll-snap-align: start;

        img {
          width: 48.5vw;
          height: auto;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .productList {
    min-height: 400px;
    margin: 12vh 0;

    .productList__main {
      height: 400px;
      padding: 40px 4vw;
      background: url('../../public/images/universal/bg-product-list.png');
      background-repeat: no-repeat;
      background-position: bottom;
      object-position: bottom;
      background-size: 100% auto;
      padding: 8vh 4vw;

      .product-slider {
        width: 100%;

        .product-item {
          width: 17vw;

          img {
            width: 17vw;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .productList {
    min-height: 500px;
    margin: 10vh 0;

    .productList__main {
      height: 500px;
      padding: 40px 4vw;

      .product-slider {
        width: 100%;

        .product-item {
          width: 18vw;

          img {
            width: 18vw;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .productList {
    min-height: 610px;
    margin: 12vh 0;

    .productList__main {
      height: 610px;
      padding: 40px 4vw;

      .product-slider {
        width: 100%;

        .product-item {
          width: 18vw;

          img {
            width: 18vw;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .productList {
    min-height: 680px;
    margin: 12vh 0;

    .productList__main {
      height: 680px;
      padding: 40px 4vw;

      .product-slider {
        width: 100%;

        .product-item {
          width: 18vw;

          img {
            width: 18vw;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1536px) {
  .productList {
    min-height: 710px;
    margin: 12vh 0;

    .productList__main {
      height: 710px;
      padding: 40px 4vw;

      .product-slider {
        width: 100%;

        .product-item {
          width: 18vw;

          img {
            width: 18vw;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .productList {
    min-height: 830px;
    margin: 12vh 0;

    .productList__main {
      height: 830px;
      padding: 40px 4vw;

      .product-slider {
        width: 100%;

        .product-item {
          width: 18vw;

          img {
            width: 18vw;
          }
        }
      }
    }
  }
}
