.faqPage {
  &-title {
    background: var(--color-gradient-primary);

    background-size: 100%;
    background-repeat: repeat;

    /* This will show the gradient as a text color rather than element bg. */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -ms-background-clip: text;
    -ms-text-fill-color: transparent;
    -o-background-clip: text;
    -o-text-fill-color: transparent;
  }
}

@media screen and (max-width: 640px) {
  .faqPage {
    &__list {
      // Scroll Snap
      overflow-x: scroll;
      scrollbar-width: none;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        display: none;
      }

      &-item {
        scroll-snap-stop: always;
        scroll-snap-align: center;
      }
    }
  }
}
