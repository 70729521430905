.findBrands {
  height: auto;

  .findBrands__main {
    padding-top: 50px;
    width: 100%;
    height: 100%;
    background: url('../../public/images/universal/bg-white.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
    object-position: top;
    display: flex;
    align-items: center;
    align-content: flex-end;
    flex-wrap: wrap;

    &-list {
      width: 100%;
      // height: 65%;
      height: auto;
      margin-top: 30px;
      display: flex;
      align-items: center;
      align-content: flex-end;
      flex-wrap: wrap;
      background-color: #ffffff;
      padding: 0 4vw;
      justify-content: center;

      .partner-item {
        border-radius: 12px;
        overflow: hidden;

        img {
          margin: 0 auto;
          width: 90%;
          height: auto;
          object-fit: cover;
          // border-radius: 12px;
        }

        .partner-item-btn {
          border-radius: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .findBrands {
    // height: 610px;
    height: auto;

    .findBrands__main {
      &-list {
        // height: 65%;
        height: auto;
        margin-top: 60px;
        padding: 0 4vw;
        justify-content: start;

        .partner-item {
          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .findBrands {
    // height: 590px;
    height: auto;

    .findBrands__main {
      &-list {
        // height: 65%;
        height: auto;
        margin-top: 100px;
        padding: 0 4vw;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .findBrands {
    // height: 690px;
    height: auto;

    .findBrands__main {
      &-list {
        // height: 65%;
        height: auto;
        margin-top: 140px;
        padding: 0 4vw;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .findBrands {
    // height: 750px;
    height: auto;

    .findBrands__main {
      &-list {
        // height: 65%;
        height: auto;
        margin-top: 150px;
        padding: 0 4vw;
      }
    }
  }
}

@media screen and (min-width: 1536px) {
  .findBrands {
    // height: 810px;
    height: auto;

    .findBrands__main {
      &-list {
        // height: 65%;
        height: auto;
        margin-top: 150px;
        padding: 0 4vw;
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .findBrands {
    // height: 1000px;
    height: auto;

    .findBrands__main {
      &-list {
        // height: 65%;
        height: auto;
        margin-top: 200px;
        padding: 0 4vw;
      }
    }
  }
}
