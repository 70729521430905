.hero {
  &__left {
    padding: 4vw 0 0 4vw;
  }

  &__right {
    &-main {
      height: 87vh;
      align-items: flex-start;
      // align-items: center;
      // padding-top: 22vh;
      padding-top: 150px;

      .hero__right-products {
        width: 80%;
        position: relative;
        display: flex;
        justify-content: center;
        margin-left: auto;
        // height: 46vh;
        height: 40vh;
        // margin-top: 100px;

        img {
          position: absolute;
          bottom: 0;
          width: 12vw;
          user-select: none;
        }

        .arrow-left-btn {
          position: absolute;
          width: 3.6vw;
          bottom: -12vh;
          left: 44%;
          transform: translateX(-46%);
          -webkit-transform: translateX(-46%);
          -moz-transform: translateX(-46%);
          -ms-transform: translateX(-46%);
          -o-transform: translateX(-46%);
          cursor: pointer;
        }
        .arrow-right-btn {
          position: absolute;
          width: 3.6vw;
          bottom: -12vh;
          left: 56%;
          transform: translateX(-54%);
          -webkit-transform: translateX(-54%);
          -moz-transform: translateX(-54%);
          -ms-transform: translateX(-54%);
          -o-transform: translateX(-54%);
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .hero {
    &__mobile {
      background: url('../../public/images/universal/bg-hero-sm.png');
      background-position: top right;
      background-size: auto 100%;
      background-repeat: no-repeat;
    }

    &__left {
      padding: 3.8vw 3.6vw 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .hero {
    &__left {
      padding: 3.8vw 0 0 3.6vw;
    }

    &__right {
      &-main {
        padding-top: 140px;

        .hero__right-products {
          height: 34vh;

          .arrow-left-btn {
            width: 3.8vw;
            bottom: -10vh;
          }
          .arrow-right-btn {
            width: 3.8vw;
            bottom: -10vh;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .hero {
    &__left {
      padding: 4vw 0 0 4.2vw;
    }

    &__right {
      &-main {
        padding-top: 150px;

        .hero__right-products {
          height: 36vh;

          .arrow-left-btn {
            width: 3.8vw;
            bottom: -10vh;
          }
          .arrow-right-btn {
            width: 3.8vw;
            bottom: -10vh;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .hero {
    &__left {
      padding: 4vw 0 0 4.2vw;
    }

    &__right {
      &-main {
        padding-top: 150px;

        .hero__right-products {
          height: 36vh;

          .arrow-left-btn {
            width: 3.6vw;
            bottom: -10.5vh;
          }
          .arrow-right-btn {
            width: 3.6vw;
            bottom: -10.5vh;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1536px) {
  .hero {
    &__left {
      padding: 4vw 0 0 4.2vw;
    }

    &__right {
      &-main {
        padding-top: 160px;

        .hero__right-products {
          height: 42vh;

          .arrow-left-btn {
            width: 3.6vw;
            bottom: -11.5vh;
          }
          .arrow-right-btn {
            width: 3.6vw;
            bottom: -11.5vh;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .hero {
    &__left {
      padding: 5vw 0 0 4.2vw;
    }

    &__right {
      &-main {
        padding-top: 200px;

        .hero__right-products {
          height: 38vh;

          .arrow-left-btn {
            width: 4vw;
            bottom: -12vh;
          }
          .arrow-right-btn {
            width: 4vw;
            bottom: -12vh;
          }
        }
      }
    }
  }
}
