.forYourInfo {
  position: relative;
  height: 600px;

  h3 {
    background: var(--color-gradient-primary);

    background-size: 100%;
    background-repeat: repeat;

    /* This will show the gradient as a text color rather than element bg. */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -ms-background-clip: text;
    -ms-text-fill-color: transparent;
    -o-background-clip: text;
    -o-text-fill-color: transparent;
  }

  &-img {
    position: absolute;
    width: auto;
    height: 410px;
    object-fit: contain;
    bottom: 0;

    &-doc {
      position: absolute;
      width: auto;
      height: 100%;
    }

    &-doc-hand {
      position: absolute;
      width: auto;
      height: calc(410px / 3.5);
      bottom: calc(410px / -10);
      left: 50px;
    }

    &-hover {
      position: absolute;
      width: auto;
      height: calc(410px / 3);
      top: -120px;
      left: 280px;
    }

    &-lamp {
      width: 92px;
      height: auto;
      position: absolute;
      top: -60px;
      right: -60px;
      cursor: pointer;
    }
  }

  &-bubble {
    position: absolute;
    width: auto;
    height: 200px;
    object-fit: contain;
    left: 64%;
    top: 50%;
    transform: translate(-64%, -50%);
    -webkit-transform: translate(-64%, -50%);
    -moz-transform: translate(-64%, -50%);
    -ms-transform: translate(-64%, -50%);
    -o-transform: translate(-64%, -50%);

    &-img {
      width: auto;
      height: 100%;
      position: relative;
      left: 0;
      z-index: 0;
    }

    &-close {
      position: absolute;
      top: -35px;
      right: -35px;
      color: #ffffff;
      cursor: pointer;
    }

    &-text {
      position: absolute;
      z-index: 5;
      width: 90%;
      height: 76%;
      top: 40%;
      left: 70%;
      transform: translate(-70%, -40%);
      overflow-y: auto;
      padding-right: 10px;
      text-align: justify;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ol {
      list-style-type: decimal;
      padding-left: 20px;

      li {
        list-style-position: outside;
        list-style-type: decimal;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .forYourInfo {
    height: 430px;

    &-img {
      height: 285px;

      &-doc-hand {
        height: calc(285px / 3.5);
        bottom: calc(285px / -10);
        left: 50px;
      }

      &-hover {
        height: calc(285px / 3);
        top: -70px;
        left: 220px;
      }
    }

    &-bubble {
      height: 154px;
      left: 83%;
      top: 50%;
      transform: translate(-83%, -50%);
      -webkit-transform: translate(-83%, -50%);
      -moz-transform: translate(-83%, -50%);
      -ms-transform: translate(-83%, -50%);
      -o-transform: translate(-83%, -50%);
    }
  }
}

@media screen and (min-width: 1280px) {
  .forYourInfo {
    height: 500px;

    &-img {
      height: 320px;

      &-doc-hand {
        height: calc(320px / 3.5);
        bottom: calc(320px / -10);
        left: 50px;
      }

      &-hover {
        height: calc(320px / 3);
        top: -90px;
        left: 240px;
      }
    }

    &-bubble {
      height: 200px;
      left: 77%;
      top: 50%;
      transform: translate(-77%, -50%);
      -webkit-transform: translate(-77%, -50%);
      -moz-transform: translate(-77%, -50%);
      -ms-transform: translate(-77%, -50%);
      -o-transform: translate(-77%, -50%);
    }
  }
}

@media screen and (min-width: 1366px) {
  .forYourInfo {
    height: 520px;

    &-img {
      height: 340px;

      &-doc-hand {
        height: calc(340px / 3.5);
        bottom: calc(340px / -10);
        left: 50px;
      }

      &-hover {
        height: calc(340px / 3);
        top: -90px;
        left: 270px;
      }
    }

    &-bubble {
      height: 200px;
      left: 71%;
      top: 50%;
      transform: translate(-71%, -50%);
      -webkit-transform: translate(-71%, -50%);
      -moz-transform: translate(-71%, -50%);
      -ms-transform: translate(-71%, -50%);
      -o-transform: translate(-71%, -50%);
    }
  }
}

@media screen and (min-width: 1440px) {
  .forYourInfo {
    height: 580px;

    &-img {
      height: 380px;

      &-doc-hand {
        height: calc(380px / 3.5);
        bottom: calc(380px / -10);
        left: 50px;
      }

      &-hover {
        height: calc(380px / 3);
        top: -110px;
        left: 280px;
      }
    }

    &-bubble {
      height: 200px;
      left: 66%;
      top: 50%;
      transform: translate(-66%, -50%);
      -webkit-transform: translate(-66%, -50%);
      -moz-transform: translate(-66%, -50%);
      -ms-transform: translate(-66%, -50%);
      -o-transform: translate(-66%, -50%);
    }
  }
}

@media screen and (min-width: 1536px) {
  .forYourInfo {
    height: 600px;

    &-img {
      height: 410px;

      &-doc-hand {
        height: calc(410px / 3.5);
        bottom: calc(410px / -10);
        left: 50px;
      }

      &-hover {
        height: calc(410px / 3);
        top: -120px;
        left: 280px;
      }
    }

    &-bubble {
      height: 200px;
      left: 64%;
      top: 50%;
      transform: translate(-64%, -50%);
      -webkit-transform: translate(-64%, -50%);
      -moz-transform: translate(-64%, -50%);
      -ms-transform: translate(-64%, -50%);
      -o-transform: translate(-64%, -50%);
    }
  }
}

@media screen and (min-width: 1920px) {
  .forYourInfo {
    height: 700px;

    &-img {
      height: 500px;

      &-doc-hand {
        height: calc(500px / 3.5);
        bottom: calc(500px / -10);
        left: 50px;
      }

      &-hover {
        height: calc(500px / 3);
        top: -140px;
        left: 390px;
      }
    }

    &-bubble {
      height: 240px;
      left: 55%;
      top: 50%;
      transform: translate(-55%, -50%);
      -webkit-transform: translate(-55%, -50%);
      -moz-transform: translate(-55%, -50%);
      -ms-transform: translate(-55%, -50%);
      -o-transform: translate(-55%, -50%);
    }
  }
}
