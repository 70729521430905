.aboutUs {
  margin-bottom: 12vh;

  &__vm {
    background-color: var(--color-primary);

    &-item {
      &-box {
        height: 680px;

        h4 {
          background: var(--color-gradient-primary);

          background-size: 100%;
          background-repeat: repeat;

          /* This will show the gradient as a text color rather than element bg. */
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
          -ms-background-clip: text;
          -ms-text-fill-color: transparent;
          -o-background-clip: text;
          -o-text-fill-color: transparent;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .aboutUs {
    margin-bottom: 6vh;

    &__vm {
      &-scroll-mobile {
        scrollbar-width: none;
        overflow-x: scroll;

        // Scroll Snap
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &-item {
        scroll-snap-stop: always;
        scroll-snap-align: center;

        &-box {
          height: 660px;
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .aboutUs {
    margin-bottom: 6vh;

    &__vm {
      &-scroll-mobile {
        scrollbar-width: none;
        overflow-x: scroll;

        // Scroll Snap
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &-item {
        scroll-snap-stop: always;
        scroll-snap-align: center;

        &-box {
          height: 740px;
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .aboutUs {
    margin-bottom: 6vh;

    &__vm {
      &-scroll-mobile {
        scrollbar-width: none;
        overflow-x: scroll;

        // Scroll Snap
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &-item {
        scroll-snap-stop: always;
        scroll-snap-align: center;

        &-box {
          height: 850px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .aboutUs {
    &__vm {
      &-item {
        &-box {
          height: 770px;
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .aboutUs {
    &__vm {
      &-item {
        &-box {
          height: 800px;
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .aboutUs {
    &__vm {
      &-item {
        &-box {
          height: 750px;
        }
      }
    }
  }
}

@media screen and (min-width: 1536px) {
  .aboutUs {
    &__vm {
      &-item {
        &-box {
          height: 760px;
        }
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .aboutUs {
    &__vm {
      &-item {
        &-box {
          height: 680px;
        }
      }
    }
  }
}
