.footer {
  background: url('../../public/images/universal/bg-footer.png');
  background-position: top center;
  background-size: 100% auto;

  .socmed-icon {
    padding: 2px;
  }

  .copyright-border {
    border-top: 0.75px solid #ffffff;

    .copyright-color {
      color: rgba($color: #ffffff, $alpha: 0.5);
    }
  }
}

@media screen and (max-width: 640px) {
  .footer {
    .copyright-border {
      border-top: 0.75px solid rgba($color: #ffffff, $alpha: 0.2);

      width: 94%;
      margin: 0 auto;
    }
  }
}
