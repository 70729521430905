.linkTree {
  width: 100%;
  min-height: 100vh;
  background-image: radial-gradient(
      100% 100% at 50% 100%,
      #feb2b2 0%,
      rgba(252, 184, 219, 0.75) 25%,
      rgba(239, 194, 250, 0.5) 50%,
      rgba(190, 227, 248, 0) 100%
    ),
    linear-gradient(
      90deg,
      #3182ce 0%,
      #348fc5 14.29%,
      #389ac2 28.57%,
      #3ca5c1 42.86%,
      #41b0c2 57.14%,
      #45bbc3 71.43%,
      #4fd1c5 100%
    );
  background-image: url("../../public/images/universal/bg-link-mobile.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.linkCard {
  backdrop-filter: blur(5px) saturate(180%);
  -webkit-backdrop-filter: blur(5px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 9999px;
  border: 2px solid #5a421e;
}

.linkCard:hover {
  background-color: rgba(255, 255, 255, 0.55);
}

@media screen and (min-width: 640px) {
  .linkTree {
    background-image: url("../../public/images/universal/bg-link.png");
  }
}
