.detailsBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2px 0;

  a {
    width: 100%;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    padding: 2px 0;
    text-align: center;
  }
  a:hover {
    background-color: #ffffff;
  }

  .hover-theme-yellow:hover {
    color: var(--color-yellow);
  }

  .hover-theme-violet:hover {
    color: var(--color-violet);
  }

  .hover-theme-green:hover {
    color: var(--color-green);
  }

  .hover-theme-orange:hover {
    color: var(--color-orange);
  }

  .hover-theme-orange-dark:hover {
    color: var(--color-orange-dark);
  }

  .hover-theme-red:hover {
    color: var(--color-red);
  }

  .hover-theme-blue:hover {
    color: var(--color-blue);
  }

  .hover-theme-cyan:hover {
    color: var(--color-cyan);
  }
}

.arrowBtn {
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background: #f8f8f8;
  border: 1px solid var(--color-graylight);
  color: var(--color-graylight);
}

.arrowBtn:hover {
  background: var(--color-gradient-primary);
  border: 1px solid transparent;
  color: #ffffff;
}
