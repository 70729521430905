.buyNowModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  background: rgba($color: #000000, $alpha: 0.3);
  overflow: hidden !important;

  .buyNowModal__productImg {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
  }
}
